.fakeimg {
  background-color: black;
  width: 300px;
  height: 300px;
  border: 1px solid black
}

.nunito {
  font-family: 'Nunito'
}

.homepage-span {
  font-size: 200px;
}

.panel {
  border-radius: 16px;
  box-shadow: 0 11px 34px 0 rgb(0 0 0 / 20%);
}

.white {
  background-color: #ffffff;
}

.light-grey {
  background-color: #E3E8EE;
}

.light-azure {
  background-color: rgba(0, 180, 216, 0.5);
}

.grey {
  background-color: #A9BCD0;
}

.purple {
  background-color: #5F00BA
}

.green {
  background-color: #40b551
}

.yellow {
  background-color: #FFBE0B;
}

.azure {
  background-color: #00B4D8;
}

.color-white {
  color: #ffffff;
}

.color-orange {
  color: #F34616;
}

.button-medium {
  width: fit-content;
  height: 50px;
  border-radius: 90px;

  position: relative;
  display: inline-block;
  overflow: hidden;
}

.redirect-button:hover {
  background-color: rgba(0, 180, 216, 0.7);
}

.underline {
  text-decoration: underline;
}

.center-tag-span {
  line-height: 65px;
  height: 65px;
}

.button-label {
  height: 50px;
  line-height: 50px;
  font-size: 15px;
  position: relative;
  z-index: 1;
  transition: color 0.3s ease-in-out;
}

.logo-box {
  border-radius: 16px;
}

.logo-homepage {
  position: relative;
  z-index: 1;
  background-image: url("./assets/logotipo.svg");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  width: 500px;
  height: 220px;
  max-width: 750px !important;
  margin: 70px auto;
}

.title-homepage {
  font-size: 120px;
  line-height: 1;
  font-weight: 400;
  text-align: center;
  letter-spacing: -5px;
  word-wrap: break-word;
  z-index: 1;
  position: relative;
}

.title-page {
  font-size: 100px;
  font-weight: 400;
  text-align: left;
  letter-spacing: -5px;
  word-wrap: break-word;
  line-height: 1;
}

.title-page-l {
  font-size: 100px;
  font-weight: 500;
  font-style: oblique;
  text-align: right;
  letter-spacing: -5px;
  word-wrap: break-word;
  line-height: 1;
}

.section {
  margin: 100px 0 0 0 !important;
}

.section-little {
  margin: 50px 0 0 0 !important;
}

.center-column-size {
  -ms-flex: 0 0 800px !important;
  flex: 0 0 800px !important;
}

.page-card-size {
  -ms-flex: 0 0 520px !important;
  flex: 0 0 520px !important;
}

.store-icon-size {
  -ms-flex: 0 0 200px !important;
  flex: 0 0 200px !important;
}

.app-video-size {
  -ms-flex: 0 0 400px !important;
  flex: 0 0 400px !important;
}

.purple-border{
  width:fit-content !important;
  border-radius: 15px;
  border:2px solid rgb(116, 9, 116)
}

.page-card-padding {
  padding: 0 0 0 0.5rem !important;
}

.page-card-padding-both {
  padding: 0 0.5rem 0 0.5rem !important;
}

.email-button-div {
  -ms-flex: 0 0 280px !important;
  flex: 0 0 280px !important;
}

.phone-button-div {
  -ms-flex: 0 0 170px !important;
  flex: 0 0 170px !important;
}

.insta-button-div {
  -ms-flex: 0 0 150px !important;
  flex: 0 0 150px !important;
}

.tech-card-padding {
  padding-bottom: 0.5rem !important;
}

.tech-card-padding-footer {
  margin: 180px 0 0 0 !important;
  padding-bottom: 0.5rem !important;
}

.icon-card {
  -ms-flex: 0 0 80px !important;
  flex: 0 0 80px !important;
}

.icon-it {
  background-image: url("./assets/icons/service-icon.svg");
  background-repeat: no-repeat;
  background-size: 60px;
  background-position: center;
  width: 80px;
  height: 80px;
}

.icon-it-mobile{
  background-image: url("./assets/icons/service-icon.svg");
  background-repeat: no-repeat;
  background-size: 100px;
  background-position: center;
  width: 100px;
  height: 100px;
}

.icon-manu {
  background-image: url("./assets/icons/products-icon.svg");
  background-repeat: no-repeat;
  background-size: 60px;
  background-position: center;
  width: 80px;
  height: 80px;
}

.icon-manu-mobile{
  background-image: url("./assets/icons/products-icon.svg");
  background-repeat: no-repeat;
  background-size: 100px;
  background-position: center;
  width: 100px;
  height: 100px;
}

.icon-comm {
  background-image: url("./assets/icons/communication-icon.svg");
  background-repeat: no-repeat;
  background-size: 60px;
  background-position: center;
  width: 80px;
  height: 80px;
}

.icon-comm-mobile{
  background-image: url("./assets/icons/communication-icon.svg");
  background-repeat: no-repeat;
  background-size: 100px;
  background-position: center;
  width: 100px;
  height: 100px;
}

.icon-tech-analysis {
  background-image: url("./assets/icons/tech-analysis-icon.svg");
  background-repeat: no-repeat;
  background-size: 60px;
  background-position: center;
  width: 300px;
  height: 100%;

  -ms-flex: 0 0 300px !important;
  flex: 0 0 300px !important;
}

.icon-tech-analysis-little {
  background-image: url("./assets/icons/tech-analysis-icon.svg");
  background-repeat: no-repeat;
  background-size: 40px;
  background-position: center;
  width: 60px;
  height: 40px;

  -ms-flex: 0 0 60px !important;
  flex: 0 0 60px !important;
}

.icon-tech-build {
  background-image: url("./assets/icons/tech-build-icon.svg");
  background-repeat: no-repeat;
  background-size: 60px;
  background-position: center;
  width: 300px;
  height: 100%;

  -ms-flex: 0 0 300px !important;
  flex: 0 0 300px !important;
}

.icon-tech-build-little {
  background-image: url("./assets/icons/tech-build-icon.svg");
  background-repeat: no-repeat;
  background-size: 40px;
  background-position: center;
  width: 60px;
  height: 40px;

  -ms-flex: 0 0 60px !important;
  flex: 0 0 60px !important;
}

.icon-tech-maintenance {
  background-image: url("./assets/icons/tech-maintenance-icon.svg");
  background-repeat: no-repeat;
  background-size: 60px;
  background-position: center;
  width: 200px;
  height: 100%;

  -ms-flex: 0 0 300px !important;
  flex: 0 0 300px !important;
}

.icon-tech-maintenance-little {
  background-image: url("./assets/icons/tech-maintenance-icon.svg");
  background-repeat: no-repeat;
  background-size: 40px;
  background-position: center;
  width: 60px;
  height: 40px;

  -ms-flex: 0 0 60px !important;
  flex: 0 0 60px !important;
}

.title-card-home {
  font-size: 35px;
  font-weight: 400;
  letter-spacing: -1px;
  word-wrap: break-word;
}

.title-card-home-mobile {
  font-size: 50px;
  font-weight: 400;
  letter-spacing: -1px;
  word-wrap: break-word;
}

.title-card-home-creative {
  font-size: 40px;
  font-weight: 500;
  letter-spacing: -1px;
  word-wrap: break-word;
}

.title-card-home-creative-mobile{
  font-size: 50px;
  font-weight: 500;
  letter-spacing: -1px;
  word-wrap: break-word;
}

.click-card {
  transition: all 0.2s ease-in-out;
}

.click-card:hover {
  transform: scale(1.03);
}

.description-card-home {
  font-size: 20px;
  font-weight: 300;
  letter-spacing: 0;
}

.description-card-home-mobile{
  font-size: 40px;
  font-weight: 400;
  letter-spacing: 0;
}

.footer-span {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
}

.space3 {
  height: 3rem;
}

.nav-panel {
  border: 1.5px solid #7E8987;
  border-radius: 16px;
  background-color: #ffffff;
  color: #000000;
  height: 70px;
  padding: 0 0 0 0.25rem !important;
}

.nav-panel-mobile {
  border: 1.5px solid #7E8987;
  border-radius: 16px;
  background-color: #ffffff;
  color: #000000;
  height: 100px;
  padding: 0 0 0 0.25rem !important;
}

.navbar-logo-img {
  transition: transform 0.2s;
  z-index: 999;

  background-image: url("./assets/logotipo.svg");
  background-size: 80px;
  background-position: left;
  background-repeat: no-repeat;

  width: 80px;
  height: 70px;

  margin: 0 0 0 0.5rem;
}

.navbar-logo-img-mobile {
  transition: transform 0.2s;
  z-index: 999;

  background-image: url("./assets/pittogramma.svg");
  background-size: 80px;
  background-position: left;
  background-repeat: no-repeat;

  width: 90px;
  height: 90px;
}

/* .navbar-logo-img:hover {
  transform: scale(1.08);
} */

.navbar-div {
  position: fixed;
  z-index: 5;
}

.navbar-logo-size {
  -ms-flex: 0 0 80px !important;
  flex: 0 0 80px !important;

  display: flex;
  align-items: center;
  /* Per allineare verticalmente */
  justify-content: center;
}

.navbar-logo-size-mobile {
  -ms-flex: 0 0 90px !important;
  flex: 0 0 90px !important;

  display: flex;
  align-items: center;
  /* Per allineare verticalmente */
  justify-content: center;
  height: 80px;
  margin: auto;
}

.nav-tech-selected {
  border: 1.5px solid #5F00BA;
  border-radius: 16px;
  height: 60px;
}

.nav-prod-selected {
  border: 1.5px solid #40b551;
  border-radius: 16px;
  height: 60px;
}

.nav-comm-selected {
  border: 1.5px solid #FFBE0B;
  border-radius: 16px;
  height: 60px;
}

.nav-us-selected {
  border: 1.5px solid #00B4D8;
  border-radius: 16px;
  height: 60px;
}

.nav-tech-selected-mobile {
  border: 1.5px solid #5F00BA;
  border-radius: 16px;
  height: 80px;
}

.home-selected-mobile {
  border: 1.5px solid #E3E8EE;
  border-radius: 16px;
  height: 80px;
}

.nav-prod-selected-mobile {
  border: 1.5px solid #40b551;
  border-radius: 16px;
  height: 80px;
}

.nav-comm-selected-mobile {
  border: 1.5px solid #FFBE0B;
  border-radius: 16px;
  height: 80px;
}

.nav-us-selected-mobile {
  border: 1.5px solid #00B4D8;
  border-radius: 16px;
  height: 80px;
}

.nav-tech {
  border: 1.5px solid #ffffff;
  border-radius: 16px;
  height: 60px;
}

.nav-logo {
  border: 1.5px solid #ffffff;
  border-radius: 16px;
  height: 60px;
}

.nav-logo-no-border {
  border: 1.5px solid #ffffff;
  border-radius: 16px;
  height: 60px;
}

.nav-logo:hover {
  border: 1.5px solid #F34616;
}

.nav-prod {
  border: 1.5px solid #ffffff;
  border-radius: 16px;
  height: 60px;
}

.nav-comm {
  border: 1.5px solid #ffffff;
  border-radius: 16px;
  height: 60px;
}

.nav-us {
  border: 1.5px solid #ffffff;
  border-radius: 16px;
  height: 60px;
}

.nav-tech-mobile {
  border: 1.5px solid #ffffff;
  border-radius: 16px;
  height: 80px;
}

.nav-prod-mobile {
  border: 1.5px solid #ffffff;
  border-radius: 16px;
  height: 80px;
}

.nav-comm-mobile {
  border: 1.5px solid #ffffff;
  border-radius: 16px;
  height: 80px;
}

.nav-us-mobile {
  border: 1.5px solid #ffffff;
  border-radius: 16px;
  height: 80px;
}

.nav-tech:hover {
  border: 1.5px solid #5F00BA;
}

.nav-prod:hover {
  border: 1.5px solid #40b551;
}

.nav-comm:hover {
  border: 1.5px solid #FFBE0B;
}

.nav-us:hover {
  border: 1.5px solid #00B4D8;
}

.nav-tech-mobile:hover {
  border: 1.5px solid #5F00BA;
}

.nav-prod-mobile:hover {
  border: 1.5px solid #40b551;
}

.nav-comm-mobile:hover {
  border: 1.5px solid #FFBE0B;
}

.nav-us-mobile:hover {
  border: 1.5px solid #00B4D8;
}

.nav-title {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -1px;
  height: 60px;
  line-height: 55px;
}

.nav-title-mobile {
  font-size: 35px;
  font-weight: 500;
  letter-spacing: -1px;
  height: 80px;
  line-height: 70px;
}

.color-white {
  color: #ffffff;
}

.productsVideo {
  width: 100%;
}

.page-container {
  padding: 90px 0 0 0;
}

.page-container-mobile {
  padding: calc(0.5rem + 90px + 1.5rem) 0 0 0;
}

.platform-padd {
  padding: 0 0.5rem 0 0 !important;
}

.app-video {
  border-radius: 16px;
  width: 100%;
}

.path-video {
  width: 100%;
  position: absolute;
  z-index: -1;
}

.hidden-panel {
  border-radius: 16px;
  box-shadow: 0 11px 34px 0 rgb(0 0 0 / 20%);
  transition: transform 1s;
}

.hidden-panel:hover {
  background-color: rgba(126, 137, 135, 0.3);
}

/* .hidden-panel:hover {
  border-radius: 16px;
  box-shadow: 0 11px 34px 0 rgb(0 0 0 / 20%);
  transition: transform 1s;
} */

.overlay-text{
top:50%;
left: 50%;
transform: translateX(-50%);
}

.tag-label {
  /* font-size: 40px;
  font-weight: 500; */
  font-size: 35px;
  font-weight: 400;
  letter-spacing: -1px;
}

.tag-label-mobile{
  font-size: 50px;
  font-weight: 500;
  letter-spacing: -1px;
}

.tag-label-bold {
  font-size: 35px;
  font-weight: 400;
  letter-spacing: -1px;
}

.shadow {
  box-shadow: 0 11px 34px 0 rgb(0 0 0 / 20%);
}

.cicle-icon {
  box-shadow: 0 11px 34px 0 rgb(0 0 0 / 20%);
  width: 100px;
  height: 100px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  border-radius: 90px;
}

.cicle-icon-center {
  box-shadow: 0 11px 34px 0 rgb(0 0 0 / 20%);
  width: 100px;
  height: 100px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -75%);
  z-index: 1;
  border-radius: 90px;
}

.tag-card-div {
  width: fit-content !important;
}

.tag-card {
  border-radius: 16px;
  width: fit-content !important;
  height: 62px;
}

.tag-item {
  transition: opacity 0.5s ease, transform 0.5s ease;
  transform: scale(0.9);
}

.zoom-in {
  transform: scale(1);
  /* o il valore che preferisci per l'effetto di zoom */
}

.ball-movement {
  animation: move 300s linear;
}

.cicle-orange {
  position: absolute;
  width: 70px;
  height: 70px;
  border: 5px solid #000000;
  border-radius: 90px;
  background-color: #F34616;
}

.cicle-purple {
  position: absolute;
  width: 70px;
  height: 70px;
  border: 5px solid #000000;
  border-radius: 90px;
  background-color: #5F00BA;
}

.cicle-yellow {
  position: absolute;
  width: 70px;
  height: 70px;
  border: 5px solid #000000;
  border-radius: 90px;
  background-color: #FFBE0B;
}

.cicle-green {
  position: absolute;
  width: 70px;
  height: 70px;
  border: 5px solid #000000;
  border-radius: 90px;
  background-color: #40b551;
}

.cicle-azure {
  position: absolute;
  width: 70px;
  height: 70px;
  border: 5px solid #000000;
  border-radius: 90px;
  background-color: #00B4D8;
}

.us-image-music {
  background-image: url("./assets/team/music.jpg");
}

.us-image-murales {
  background-image: url("./assets/team/murales.jpg");
}

.us-image {
  width: 100%;
  background-position: top;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 16px;
  transition: background-color 0.8s ease;
  padding: 0 0 100% 0;
}

.p-photo {
  padding: 0 0.25rem !important;
}

.button-email-div {
  padding: 0 0 0 0.75rem !important;
  margin: 0.25rem 0 0 0.75rem !important;
}

.input-email-div {
  padding: 60px 0 0 0 !important;
}

.oblique{
  font-style: italic;
}









.navbarLogoSizeMobile {
  -ms-flex: 0 0 180px !important;
  flex: 0 0 180px !important;

  display: flex;
  align-items: center;
  /* Per allineare verticalmente */
  justify-content: center;
}

.center-span {
  display: flex;
  align-items: center;
  /* Per allineare verticalmente */
}

.center-span-top {
  display: flex;
  align-items: initial;
}

.border-light-grey {
  border: 1px solid #E3E8EE;
}

.border-green {
  border: 1px solid #40b551;
}

.border-yellow {
  border: 1px solid #FFBE0B;
}

.border-light-grey:hover {
  border: 1px solid #E3E8EE;
  background-color: #E3E8EE;
}

.border-green:hover {
  border: 1px solid #40b551;
  background-color: #40b551;
}

.border-yellow:hover {
  border: 1px solid #FFBE0B;
  background-color: #FFBE0B;
}

.contact-button {
  border-radius: 90px;
  height: 40px;
}

.email-transition-in {
  opacity: 0;
  position: absolute;
  left: 10px;
  top: 7.3px;
}

.move-in {
  opacity: 1;
}

.email-transition-out {
  opacity: 1;
  position: absolute;
  left: 10px;
  top: 7.3px;
}

.move-out {
  opacity: 0;
}

.max-width-card-tech {
  max-width: 450px !important;
}

.max-width-card-section {
  max-width: 1000px !important;
}

.absolute {
  position: absolute;
}

.cicle-center {
  top: 100%;
}






.title-descr-container {
  display: flex;
  align-items: center;
  /* Per allineare verticalmente */
}

.navbarIconImgMobile {
  width: 45px;
  height: 45px;
}

.navbarIconImg {
  width: 30px;
  height: 30px;
}


.iconButton {
  background-color: #ffffff !important;
}

.iconButtonCell {
  background-color: #ffffff !important;
  border: 5px solid black !important;
}

.iconButton-navbar {
  background-color: #ffffff !important;
  border: 3px solid black !important;
}

.iconButton-navbar-active {
  background-color: #00b4d8 !important;
  border: 3px solid black !important;
}

.iconButton-navbar:hover {
  background-color: #ccff66 !important;
  border: 3px solid black;
}

.floatRight {
  float: right;
}

.alignCenter {
  text-align: center;
}

.pointer {
  cursor: pointer;
}

.normal {
  cursor: default;
}

.biggerEffect {
  transition: transform 0.2s;
}

.biggerEffect:hover {
  transform: scale(1.08);
}



.hr-homepage {
  color: black;
  height: 3px;
}

.hr-product {
  color: black;
  height: 3px;
  margin: 0 !important;
}

.text-align-center {
  text-align: center;
}

.text-align-right {
  text-align: right;
}

.text-align-left {
  text-align: left;
}

.space15 {
  height: 15px;
}

.space30 {
  height: 30px;
}

.space50 {
  height: 50px;
}

.space100 {
  height: 100px;
}

.space200 {
  height: 200px;
}

.text-container {
  padding: 0 3vw 0 3vw !important;
}

.title-card {
  background-color: #8338ec;
  border-radius: 15px;
  height: 40px;

  display: flex;
  /* Utilizziamo il display flex sul contenitore */
  align-items: center;
  /* Centra verticalmente gli elementi figlio */
  justify-content: center;
  border: 2px solid black;
}

.title-card-mobile {
  background-color: #8338ec;
  border-radius: 15px;
  height: 55px;

  display: flex;
  /* Utilizziamo il display flex sul contenitore */
  align-items: center;
  /* Centra verticalmente gli elementi figlio */
  justify-content: center;
  border: 2px solid black;
}

.title-card-blue {
  background-color: #00b4d8;
  border-radius: 15px;
  height: 40px;

  display: flex;
  /* Utilizziamo il display flex sul contenitore */
  align-items: center;
  /* Centra verticalmente gli elementi figlio */
  justify-content: center;
  border: 2px solid black;
}

.title-card-blue-mobile {
  background-color: #00b4d8;
  border-radius: 15px;
  height: 55px;

  display: flex;
  /* Utilizziamo il display flex sul contenitore */
  align-items: center;
  /* Centra verticalmente gli elementi figlio */
  justify-content: center;
  border: 2px solid black;
}

.icon-description {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 30px;
}

.padding-page-cell {
  padding: 100px 100px 0 100px;
}

.padding-page-cell-x {
  padding: 0 100px 0 100px !important;
}

.padding-page-cell-top {
  padding: 100px 0 0 0;
}

.margin-logo-cell {
  margin: 80px 0 0 0;
}

.product-title-page {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.img-stayin-alive {
  width: 100%;
  padding: 0 0 100% 0;
  background-image: url("./assets/products/stayin-alive.png");
  background-position: top;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 16px;
  transition: background-color 0.8s ease;
}

.img-plate {
  width: 100%;
  padding: 0 0 100% 0;
  background-image: url("./assets/products/plate.png");
  background-position: top;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 16px;
  transition: background-color 0.8s ease;
}

.img-cups {
  width: 100%;
  padding: 0 0 100% 0;
  background-image: url("./assets/products/cups.png");
  background-position: top;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 16px;
  transition: background-color 0.8s ease;
}

.img-bag {
  width: 100%;
  padding: 0 0 100% 0;
  background-image: url("./assets/products/bag.jpg");
  background-position: top;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 16px;
  transition: background-color 0.8s ease;
}

.title-prod-card-more {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  padding: 0 1rem;

  font-size: 70px;
  font-weight: 700;
  line-height: 1;
}

.title-prod-card {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  width: 100%;
  padding: 0 1rem;

  font-size: 70px;
  font-weight: 700;
  line-height: 1;
}

.title-us-card {
  position: absolute;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;

  font-size: 35px;
  font-weight: 400;
  letter-spacing: -1px;
}

.subtitle-prod-card-more {
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translate(-50%, 0);
  width: 100%;
  padding: 0 1rem;

  font-size: 20px;
  font-weight: 500;
  line-height: 1;
}

.subtitle-prod-card {
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translate(-50%, 0);
  opacity: 0;
  width: 100%;
  padding: 0 1rem;

  font-size: 20px;
  font-weight: 500;
  line-height: 1;
}

.img-stayin-alive:hover {
  background-color: #c32120;
  background-image: none;
}

.img-plate:hover {
  background-color: #bca498;
  background-image: none;
}

.img-cups:hover {
  background-color: #5F00BA;
  background-image: none;
}

.img-bag:hover {
  background-color: #40b551;
  background-image: none;
}

.img-prod:hover .title-prod-card {
  opacity: 1 !important;
}

.img-prod:hover .subtitle-prod-card {
  opacity: 1 !important;
}

.img-cups:hover .text-hover {
  opacity: 1;
}

.relative {
  position: relative;
}

.footer-margin {
  margin: 0 0 1rem 0 !important;
}

.text-hover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  /* background-color: rgba(0, 0, 0, 0.7); */
  background-color: rgba(255, 255, 255, 0.7);
  opacity: 0;
  /* Inizialmente nascosto */
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s ease-in-out;
  color: black;
}

.text-hover span {
  color: black;
  text-align: center;
}

.display-none {
  display: none;
}

.icon-navbar-size {
  -ms-flex: 0 0 80px !important;
  flex: 0 0 80px !important;
}

.icon-navbar-size-mobile {
  -ms-flex: 0 0 130px !important;
  flex: 0 0 130px !important;
}

.icon-navbar-size-long {
  -ms-flex: 0 0 160px !important;
  flex: 0 0 160px !important;
}

.icon-navbar-size-mobile-long {
  -ms-flex: 0 0 270px !important;
  flex: 0 0 270px !important;
}



.about-us-1 {
  height: 800px;
  z-index: -1;
  overflow-x: hidden;
}

.about-us-2 {
  height: 1600px;
  z-index: -2;
  overflow-x: hidden;
}

.about-us-3 {
  height: 1600px;
  z-index: -3;
  overflow-x: hidden;
}

.about-us-comment {
  width: 300px;
  padding: 10px;
  background-color: #E3E8EE;
  z-index: 1;
  border-radius: 16px;
}

.about-us-comment-icon {
  width: 30px;
  height: 30px;
  background-size: 30px 30px;
  position: absolute;
  top: -15px;
  left: -15px;
  background-image: url("../src/assets/icons/service-icon.svg");

}



/* Extra Little Monitors */
@media screen and (max-width: 360px) {

  .homepage-icon-service {
    width: 25px;
    height: 25px;
  }

  .button-icon-size-homepage {
    max-width: 50px;
    max-height: 50px;
    min-width: 50px;
    min-height: 50px;
  }

  .iconButton:hover {
    background-color: #ccff66 !important;
    border: 2px solid black;

  }

  .button-icon-size-navbar {
    max-width: 70px;
    max-height: 70px;
    min-width: 70px;
    min-height: 70px;
  }

  .iconButton {
    border: 2px solid black !important;
  }
}

/* Little Monitors */
@media screen and (min-width: 361px) and (max-width: 500px) {

  .homepage-icon-service {
    width: 35px;
    height: 35px;
  }

  .button-icon-size-homepage {
    max-width: 70px;
    max-height: 70px;
    min-width: 70px;
    min-height: 70px;
  }

  .iconButton:hover {
    background-color: #ccff66 !important;
    border: 3px solid black;

  }

  .button-icon-size-navbar {
    max-width: 70px;
    max-height: 70px;
    min-width: 70px;
    min-height: 70px;
  }

  .iconButton {
    border: 3px solid black !important;
  }
}

/* Little-Medium Monitors */
@media screen and (min-width: 501px) and (max-width: 750px) {

  .homepage-icon-service {
    width: 45px;
    height: 45px;
  }

  .button-icon-size-homepage {
    max-width: 80px;
    max-height: 80px;
    min-width: 80px;
    min-height: 80px;
  }

  .iconButton:hover {
    background-color: #ccff66 !important;
    border: 3px solid black;

  }

  .button-icon-size-navbar {
    max-width: 70px;
    max-height: 70px;
    min-width: 70px;
    min-height: 70px;
  }

  .iconButton {
    border: 3px solid black !important;
  }
}

/* Medium Monitors */
@media screen and (min-width: 751px) and (max-width: 840px) {

  .homepage-icon-service {
    width: 45px;
    height: 45px;
  }

  .button-icon-size-homepage {
    max-width: 80px;
    max-height: 80px;
    min-width: 80px;
    min-height: 80px;
  }

  .iconButton:hover {
    background-color: #ccff66 !important;
    border: 4px solid black;

  }

  .button-icon-size-navbar {
    max-width: 70px;
    max-height: 70px;
    min-width: 70px;
    min-height: 70px;
  }

  .iconButton {
    border: 4px solid black !important;
  }
}

/* Medium-Big Monitors */
@media screen and (min-width: 841px) and (max-width: 1200px) {
  .homepage-icon-service {
    width: 55px;
    height: 55px;
  }

  .button-icon-size-homepage {
    max-width: 100px;
    max-height: 100px;
    min-width: 100px;
    min-height: 100px;
  }

  .iconButton:hover {
    background-color: #ccff66 !important;
    border: 4px solid black;

  }

  .button-icon-size-navbar {
    max-width: 70px;
    max-height: 70px;
    min-width: 70px;
    min-height: 70px;
  }

  .iconButton {
    border: 4px solid black !important;
  }

  .text-hover {
    height: calc(50vw - 6vw - 1.5rem);
  }
}

/* Big Monitors */
@media screen and (min-width: 1201px) {


  .homepage-icon-service {
    width: 55px;
    height: 55px;
  }

  .button-icon-size-homepage {
    max-width: 100px;
    max-height: 100px;
    min-width: 100px;
    min-height: 100px;
  }

  .iconButton:hover {
    background-color: #ccff66 !important;
    border: 4px solid black;

  }

  .button-icon-size-navbar {
    max-width: 70px;
    max-height: 70px;
    min-width: 70px;
    min-height: 70px;
  }

  .iconButton {
    border: 4px solid black !important;
  }

  .text-hover {
    height: calc(50vw - 6vw - 1.5rem);
  }
}

@media screen and (max-width: 991px) {
  .text-hover {
    height: 50vw !important;
    width: 50vw !important;
    transform: translate(-50%, 0);
    left: 50% !important;
  }
}

.underline {
  text-decoration: underline;
}
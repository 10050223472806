body{
  background-color: #ffffff !important;
}
@font-face {
  font-family: 'Poppins';
  src: local('Poppins'),
  url('./fonts/Poppins-Regular.woff') format('woff');
}

@font-face {
  font-family: 'PoppinsBold';
  src: local('PoppinsBold'),
  url('./fonts/Poppins-Bold.woff') format('woff');
}

@font-face {
  font-family: 'PoppinsSemiBold';
  src: local('PoppinsSemiBold'),
  url('./fonts/Poppins-Semibold.woff') format('woff');
}

@font-face {
  font-family: 'PoppinsLight';
  src: local('PoppinsLight'),
  url('./fonts/Poppins-Light.woff') format('woff');
}

@font-face {
  font-family: 'PoppinsMedium';
  src: local('PoppinsMedium'),
  url('./fonts/Poppins-Medium.woff') format('woff');
}

.Poppins {
  font-family: Poppins;
}
.PoppinsBold {
  font-family: PoppinsBold;
}
.PoppinsSemiBold {
  font-family: PoppinsSemiBold;
}
.PoppinsLight {
  font-family: PoppinsLight;
}
.PoppinsMedium {
  font-family: PoppinsMedium;
}